import { Logout } from "@mui/icons-material";
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSidebar } from '../context/sidebarContext.jsx';
import "./left-sidebar.scss";

export const NAVIGATION_ITEMS = [
  // {
  //   path: '/csv',
  //   label: 'Upload CSV',
  //   icon: ListAltTwoToneIcon
  // },
  {
    path: '/pdf',
    label: 'Chat With PDF',
    icon: ChatTwoToneIcon
  },
  // {
  //   path: '/reviews_input',
  //   label: 'Paste Review',
  //   icon: ChatTwoToneIcon
  // },
  {
    path: '/product_description',
    label: 'Product Description',
    icon: Inventory2TwoToneIcon
  }
];

export default function LeftSidebar() {
  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { myValue, setMyValue } = useSidebar();

  const getFilteredItems = () => {
    const linksParam = searchParams.get('links');
    if (!linksParam) return NAVIGATION_ITEMS;

    const requestedPaths = linksParam.split(',');
    return NAVIGATION_ITEMS.filter(item => requestedPaths.includes(item.path));
  };

  useEffect(() => {
    console.log("my value", myValue);
  }, [myValue]);

  const leftSidebarClick = () => {
    setMyValue(!myValue);
  };

  const handleNavigation = (path) => {
    const linksParam = searchParams.get('links');
    const queryString = linksParam ? `?links=${linksParam}` : '';
    navigate(`${path}${queryString}`);
  };

  return <div className={myValue ? "left-sidebar left-sidebar-open" : "left-sidebar d-md-block d-none"} onClick={leftSidebarClick}>
    <div className="left-sidebar-inner px-3 pb-4 h-100">
      <div className="my-3 logo-block">
        <h3>TOPS AI Demo</h3>
      </div>
      <ul className="sidebar-ul px-0 h-100 d-flex flex-column pt-2">
        {getFilteredItems().map(({ path, label, icon: Icon }) => (
          <li
            key={path}
            className={location.pathname === path ? "my-3 active" : "my-3"}
          >
            <a className="stretched-link" style={{ "cursor": "pointer" }} onClick={() => handleNavigation(path)}>
              <Icon className="me-2" />{label}
            </a>
          </li>
        ))}
        <li className="mt-auto">
          <div className="logout">
            <p className="mb-0 p-1">
              <a href="/" className="text-white d-flex">
                <Logout className="icon text-red me-2" /> <span>Logout</span>
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>;
}
