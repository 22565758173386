// Third-Party Imports
import axios from "axios";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";

// Reactstrap Imports
import { Button, Container, Input } from 'reactstrap';

// Styles
import "./pdf.scss";

// Local Components
import LeftSidebar from "../../Components/LeftSidebar";
import Topbar from "../../Components/Topbar";
import Instructions from "./components/Instructions";

// Local imports
import { BASE_URL } from "../../Constants";

function Pdf() {
  let navigate = useNavigate();

  // Redirect if not logged in
  useEffect(() => {
    if (window.sessionStorage.getItem("token") == null) {
      navigate("/");
    }
  });

  // * State
  const [useOpenAI, setUseOpenAI] = useState(true)

  const [query, setQuery] = useState("");
  // const dummyChatLog = [
  //   { content: "Hey there", role: "user" },
  //   { content: "Hello, I am AI. How can I help you?", role: "assistant" },
  //   { content: "I want to know about the product", role: "user" },
  //   { content: "Sure, please upload the product description file", role: "assistant" },
  // ]
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  // * Fetch uploaded files on OpenAI
  const fetchUploadedFiles = () => {
    setIsLoading(true)
    getUploadedFiles().then(res => {
      console.log(res)
      // setUploadedFiles(prevFiles => {

      //   // Don't add duplicate files due to re-render
      //   if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
      //     return [...prevFiles, ...res.data]
      //   }

      //   return prevFiles
      // });
      setUploadedFiles(res.data)
    }).finally(() => {
      setIsLoading(false)
    })
  }
  // useEffect(fetchUploadedFiles, [])

  // Fetch when useOpenAI Changes
  useEffect(fetchUploadedFiles, [useOpenAI])

  // * File
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleChange = async (files) => {
    setIsLoading(true)
    try {
      const response = await uploadFiles(files)
      setUploadedFiles(prevFiles => [...prevFiles, ...response.data])
    } finally {
      setIsLoading(false)
    }
  };
  const handleStartChat = async () => {

    setIsLoading(true)
    setChatLog(prevChatLog => [...prevChatLog, { content: query, role: "user" }])
    console.log("Got the submission...")
    try {
      const response = await getAIResponse()
      console.log(response)
      let content = response.data.content
      if (response.data.citation)
        content += "\n\n" + response.data.citation.join("\n")
      setChatLog(prevChatLog => [...prevChatLog, { content: content, role: "assistant" }])
    } finally {
      setIsLoading(false)
      setQuery("") // Reset query
    }
  }

  // * API
  const removeOpenAIUploadedFile = async (file_id) => {
    const token = window.sessionStorage.getItem("token", "");
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Token ${token}`,
    }

    await axios.delete(
      `${BASE_URL}openai/assistant/upload/${file_id}`,
      { headers }
    );

    fetchUploadedFiles()
  }
  const getUploadedFiles = async () => {
    const token = window.sessionStorage.getItem("token", "");
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Token ${token}`,
    }

    console.log("useOpenAI", useOpenAI)
    const url = useOpenAI ? "openai/assistant/upload/" : "local-rag/files/"
    return await axios.get(
      `${BASE_URL}${url}`,
      { headers }
    );
  }

  const uploadFiles = async (files) => {
    const token = window.sessionStorage.getItem("token", "");
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Token ${token}`,
    }

    console.log("files", files)
    const formData = new FormData()
    Array.from(files).forEach(file => formData.append(file.name, file));

    return await axios.post(
      `${BASE_URL}openai/assistant/upload/`,
      formData,
      { headers }
    );
  }
  const getAIResponse = async () => {
    const token = window.sessionStorage.getItem("token", "");
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Token ${token}`,
    }

    console.log(query)
    const formData = new FormData()
    // uploadedFiles.map(file => formData.append(file.name, file))
    // formData.append('files', uploadedFiles)
    formData.append('query', query)

    let url = `${BASE_URL}chat/pdf/`
    if (useOpenAI)
      url += `?openai=true`
    return await axios.post(
      url,
      formData,
      { headers }
    );
  }



  return (
    <div className="csv d-block">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100">
        <LeftSidebar></LeftSidebar>
        <div className="content-box">
          <div className="content-box-inner">
            <div className="d-flex flex-column" style={{ minHeight: '100dvh' }}>

              {/* Floating Instructions */}
              <Instructions></Instructions>

              {/* Header */}
              <header className="bg-white header-section">
                <Container className="p-4">
                  <h6 className="fw-600 mb-0">Chat with PDF</h6>
                </Container>
              </header>

              {/* Body */}
              <section className="flex-grow-1 p-4">
                <p>Uploaded files:</p>
                <ul>
                  {uploadedFiles.map((file, index) => {
                    return (
                      <li key={file.id}>
                        <p>{file.filename} ({file.id})</p>
                        {useOpenAI && <button onClick={async () => await removeOpenAIUploadedFile(file.id)}>Remove</button>}
                      </li>
                    )
                  })}
                </ul>
                <hr />
                {/* <pre>
                  {aIResponse}
                </pre> */}
                {chatLog.map((msg, index) => {
                  return <div className="d-flex " key={index}>
                    <div className="fw-600 flex-shrink-0" style={{ verticalAlign: 'top', width: '100px' }}>
                      <span>{msg.role === 'user' ? 'Question' : 'Response'}:</span>
                    </div>
                    <div className="">
                      <pre style={{ whiteSpace: 'pre-wrap' }}>{msg.content}</pre>
                    </div>
                  </div>
                })}
                {isLoading && <div className="text-primary mb-3">AI is processing your request...</div>}
              </section>

              {/* File Upload & Prompt handler */}
              <section className="p-4 bg-white">
                {/* <p className="small text-brand fw-500 mb-0 text-start mb-2">{uploadedFile.name}</p> */}
                <div className="d-flex align-items-center gap-4">
                  <div>
                    <Input type="checkbox" hidden className="me-2 d-inline-bock" checked={useOpenAI} onChange={e => setUseOpenAI(e.target.checked)} />

                  </div>
                  <FileUploader maxSize={50} disabled={isLoading} multiple={true} name="file" handleChange={handleChange} types={["pdf"]} />
                  <form onSubmit={e => e.preventDefault()} className="d-flex flex-grow-1 gap-4">
                    <Input disabled={isLoading} type="text" value={query} onChange={event => setQuery(event.target.value)} placeholder="Your Query..." />
                    <Button disabled={isLoading} type="submit" color="primary" className="flex-shrink-0" onClick={handleStartChat}>Ask AI</Button>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pdf;
