import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMemo, useState } from 'react';
import { NAVIGATION_ITEMS } from './LeftSidebar';

export default function LinkConfig() {
    const [selectedLinks, setSelectedLinks] = useState([]);

    const handleCheckboxChange = (path) => {
        setSelectedLinks(prev =>
            prev.includes(path)
                ? prev.filter(p => p !== path)
                : [...prev, path]
        );
    };

    const generatedUrl = useMemo(() => {
        const baseUrl = window.location.origin;
        const queryParam = selectedLinks.length
            ? `?links=${selectedLinks.join(',')}`
            : '';
        return `${baseUrl}/${queryParam}`;
    }, [selectedLinks]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedUrl);
    };

    return (
        <div className="container p-4">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-3">Select navigation items to show:</h5>
                            {NAVIGATION_ITEMS.map(({ path, label }) => (
                                <div className="form-check mb-2" key={path}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={path}
                                        checked={selectedLinks.includes(path)}
                                        onChange={() => handleCheckboxChange(path)}
                                    />
                                    <label className="form-check-label" htmlFor={path}>
                                        {label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Generated URL</h5>
                            <div className="input-group mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={generatedUrl}
                                    readOnly
                                />
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={copyToClipboard}
                                >
                                    <ContentCopyIcon />
                                </button>
                            </div>
                            <small className="text-muted mt-2 d-block">
                                This URL will display only the selected navigation items
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
