// import Topbar from "./Components/Topbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import Csv from "./Pages/Csv/Csv";
import Login from "./Pages/Login/Login";
import Pdf from "./Pages/Pdf/Pdf";
import Scrapping from "./Pages/Scrapping/Scrapping";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LinkConfig from "./Components/LinkConfig";
import { SidebarProvider } from "./context/sidebarContext";
import ProductDescription from "./Pages/ProductDescription/ProductDescription";
import ReviewsInput from "./Pages/ReviewsInput/ReviewsInput";

const router = createBrowserRouter([
  {
    path: "/",
    element:<Login/>,
  },
  // {
  //   path: "/home",
  //   element:<Home/>,
  // },
  {
    path: "/csv",
    element: <Csv/>,
  },
  {
    path: "/pdf",
    element: <Pdf/>,
  },
  {
    path: "/scrapping",
    element: <Scrapping/>,
  },
  {
    path: "/product_description",
    element: <ProductDescription/>,
  },
  {
    path: "/reviews_input",
    element: <ReviewsInput/>,
  },
  {
    path: "/dynamic-sidebar",
    element: <LinkConfig />
  }
]);


function App() {
  return (
    <SidebarProvider>
    <div className="app">
      {/* <Topbar></Topbar> */}
      <RouterProvider router={router} />
    </div>
    </SidebarProvider>
    
  );
}

export default App;
