import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from "react";
// import { useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { fileUpload } from "../../Api";
import "./csv.scss";
// import upload from "../../upload.svg"
import { Container, Row, UncontrolledTooltip } from 'reactstrap';
// import StarIcon from '@mui/icons-material/Star';
import LeftSidebar from "../../Components/LeftSidebar";
import Topbar from "../../Components/Topbar";
// import {useSidebar} from '../../context/sidebarContext.jsx';
// import { minHeight } from "@mui/system";
// import { IntegrationInstructions } from "@mui/icons-material";
// 

function Csv() {
  const [fileName, setFileName] = useState("");
  const [fileUploaded, setFileUploaded] = useState(null);
  // const hiddenFileInput = useRef(null);
  const [gotResponse, setGotResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [error, setError] = useState(false);
  const [reviewCount, setReviewCount] = useState({});
  // const {myValue, setMyValue} = useSidebar();

  const totalreview = reviewCount["5"] + reviewCount["4"] + reviewCount["3"] + reviewCount["2"] + reviewCount["1"];
  const avgreview = (reviewCount["5"] * 5 + reviewCount["4"] * 4 + reviewCount["3"] * 3 + reviewCount["2"] * 2 + reviewCount["1"] * 1) / (totalreview)
  let navigate = useNavigate();
  const fileTypes = ["csv"];

  useEffect(() => {
    // setMyValue(false);
    // 
    // console.log("token",window.sessionStorage.getItem("token"))
    if (window.sessionStorage.getItem("token") == null) {
      navigate("/");
    }
  });

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  const handleChange = (file) => {
    console.log("handle change called")
    // const fileName = event.target.files[0].name;
    // const file = event.target.files[0];
    setFileName(file.name);
    console.log(file);
    setFileUploaded(file);
  };
  const [isActive, setActive] = useState(false);

  const InstructionShow = () => {
    // console.log('click');
    setActive(!isActive);
  };


  const HandleUpload = async () => {
    if (fileUploaded !== null && !loading) {
      setLoading(true);
      setError(false);
      setStatements([]);
      setReviewCount({});
      setGotResponse(false);

      const token = window.sessionStorage.getItem("token", "");
      const resp = await fileUpload(token, fileUploaded);
      console.log("Response", resp);

      if (resp.status === 200) {
        console.log("login", resp);
        setLoading(false);
        setGotResponse(true);
        setStatements(resp.data.response);
      } else {
        console.log("in else", resp);
        setError(true);
      }

      setLoading(false);
    } else {
    }
  };
  // const [offset, setOffset] = useState(0);

  //   useEffect(() => {
  //       const onScroll = () => setOffset(window.pageYOffset);
  //       // clean up code
  //       window.removeEventListener('scroll', onScroll);
  //       window.addEventListener('scroll', onScroll, { passive: true });
  //       return () => window.removeEventListener('scroll', onScroll);
  //   }, []);
  //   console.log(offset); 



  return (
    <div className="csv d-block">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100">
        <LeftSidebar></LeftSidebar>
        <div className="content-box">
          <div className="content-box-inner">
            <div className="d-flex flex-column position-relative relative-section">
              <div className="bg-white header-section">
                <Container className="p-4 d-flex align-items-center gap-2">
                  <h6 className="fw-600 mb-0">Get Review points from CSV</h6>
                  <InfoIcon id="UncontrolledTooltipExample" className='text-secondary'></InfoIcon>
                  <UncontrolledTooltip
                    placement="right"
                    target="UncontrolledTooltipExample"
                  >
                    <p>Provide Helium10 exported file and we'll generate benefit statements from these reviews</p>
                  </UncontrolledTooltip>
                  <a style={{ "margin-left": "auto" }} href={`/Example%20H10%20Reviews.csv`} download>Download Sample CSV</a>
                </Container>
              </div>
              <div className="py-3 response-section">
                <Container className=" p-4">
                  <Row className="">
                    <div className="response-inner">
                      {/* {loading ? (
                  <div className="loading bg-transparent h-100">
                    <div id="loading-spinner">
                    </div>
                  </div>
                ) : ("")} */}
                      {gotResponse ? (
                        <div className="p-1">
                          <h4 className="mb-0 fw-600">Benefit Statements</h4>
                          <ul className="my-3 ps-2 mb-0">
                            {statements.split("\n").map((item, index) => (
                              <li className="my-1" key={{ index }}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      ) : ("")}
                    </div>
                  </Row>
                </Container>
              </div>
              {loading ? (
                <div className="loading bg-transparent h-100" style={{ minHeight: "100%" }}>
                  <div id="loading-spinner">
                  </div>
                </div>
              ) : ("")}
              <div className="position-fixed end-0 instructions-block overflow-hidden">
                <div className={"d-flex position-relative instructions-block-inner " + (isActive ? "active" : "")}>
                  <div className="align-self-center">
                    <button className={"btn fw-600 d-inline-block " + (isActive ? "btn-brand" : "btn-outline-brand")} style={{ transform: "rotate(90deg)", transformOrigin: "center" }} onClick={InstructionShow}>Instructions</button>
                    {/* <button className=" bottom-0 btn btn-info flex-shrink-1 d-inline-block" onClick={InstructionShow}>Instructions</button> */}
                  </div>
                  <div className="bg-white d-inline-block rounded-3 instruction-details ps-3">
                    <ol className="p-sm-4 p-3 mb-0 ">
                      <li>Export Reviews from Helium10.</li>
                      <li>Upload the CSV file.</li>
                      <li>Wait for analysis.</li>
                      <li>The results may not be accurate all the time.</li>
                      <li>Do not try to upload another file while one is being processed.</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="mt-auto py-2 upload-section  position-sticky border-top bottom-0 w-100 bg-brand-light">
                <Container className="p-md-4 p-3">
                  <div className="d-flex flex-column mt-auto p-1 mb-2 mx-auto upload-inner">
                    <div className="file-input">
                      <p className="small text-brand fw-500 mb-0 text-start mb-2">{error ? "" : fileName}</p>
                      <p className="error small text-red mb-0 text-start mb-2">{error ? "Error processing the file, Please check the file and try again." : ""}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="m-0 flex-fill me-3">
                        <div className="upload-file text-center w-auto w-100">
                          <FileUploader name="" handleChange={handleChange} types={fileTypes} className w-100 />
                        </div>
                      </div>
                      <div className="ps-0">
                        <button className="btn btn-brand text-nowrap" onClick={HandleUpload}>Start Analysis</button>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            {/* <Container style={{minHeight: "100%" }}>
            <Row style={{minHeight: "100%" }}>
            
            <Col className="p-md-3 p-2" sm="12">
            <h6 className="px-2 pb-3 mb-0">Upload CSV</h6>
              <div className="rounded-4 h-100 position-relative">
                <div className="d-flex flex-column h-100">
                  
                  <div className="position-absolute top-50 end-0">
                    <button className="btn btn-info" style={{ transform: "rotate(-90deg)"}}>Instructions</button>
                    <div className="mt-3 p-md-4 p-3 d-none">
                      <ul className="ps-4">
                        <li>Export Reviews from Helium10.</li>
                        <li>Upload the CSV file.</li>
                        <li>Wait for analysis.</li>
                        <li>The results may not be accurate all the time.</li>
                        <li>Do not try to upload another file while one is being processed.</li>
                      </ul>
                    </div>
                  </div>

                  <hr className="mt-auto mb-1"/>
                  <div className="p-md-4 p-3">
                  <div className="file-input mx-auto">
                        <label className="input-label" onClick={handleClick}>
                          <img className="icon" src={upload} ></img>
                        </label>
                        <p className="small text-brand fw-500 mb-0 text-start">{error ? "" : fileName}</p>
                        <p className="error mb-0 text-start">{error ? "Error processing the file, Please check the file and try again." : ""}</p>
                        <input
                          autoFocus
                          type="file"
                          hidden
                          ref={hiddenFileInput}
                          onChange={handleChange}
                        ></input>
                      </div>
                  <div className="text-center row mx-0 mb-0 ">
                    <h4 className="text-center fw-600">Upload CSV</h4>
                    <Col xs="9 px-0 alert alert-success p-0 m-0">
                    <div className="upload-file text-center w-auto" >
                      
                      <FileUploader name="" handleChange={handleChange} types={fileTypes}/>
                    </div>
                    </Col>
                    <a href="#" className="btn btn-brand" onClick={HandleUpload}>Start Analysis</a>
                    <Col xs="3" className="px-0 ps-4">
                      <button className="btn btn-brand w-100 py-2 h-100" onClick={HandleUpload}>Start Analysis</button>
                    </Col>
                  </div>
                  </div>
                </div>
              </div>
            </Col>
            </Row>
            </Container> */}
            {/* <Col className="p-md-3 p-2" lg="6">
              
                <Card className="response-card rounded-4 p-md-4 p-3 h-100">
                {loading ? (
                <div className="loading bg-transparent">
                  <div id="loading-spinner">
                  </div>
                </div>
              ) : ("")}
                {gotResponse ? (
                  <div className="p-1">
                    <div className="benefit-data">
                      <h4 className="fw-600">Benefit Statements</h4>
                        <ul className="my-3 ps-2 mb-0">
                          {statements.map((item, index) => (
                            <li className="my-1" key={index}>{item}</li>
                          ))}
                        </ul>
                    </div>
                    <hr className="my-4" />
                    <div className="review-data">
                      <h4 className="fw-600">Reviews</h4>
                      
                      <div className="row align-items-center">
                        <Col md="3" sm="12" className="text-md-left text-center">
                          <h2 className="h1">{avgreview.toFixed(1)}</h2>
                          <p className="small">{totalreview} reviews</p>
                        </Col>
                        <Col className="">
                          <div className="d-flex align-items-center justify-content-between my-1">
                            <div className="progress w-85">
                              <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["5"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["5"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ms-3 mb-0 text-end">{reviewCount["5"]}</p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between my-1">
                            <div className="progress w-85">
                              <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["4"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["4"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ms-3 mb-0 text-end">{reviewCount["4"]}</p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between my-1">
                            <div className="progress w-85">
                              <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["3"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["3"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ms-3 mb-0 text-end">{reviewCount["3"]}</p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between my-1">
                            <div className="progress w-85">
                              <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["2"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["2"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ms-3 mb-0 text-end">{reviewCount["2"]}</p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between my-1">
                            <div className="progress w-85">
                              <div className="progress-bar bg-brand rounded-3" role="progressbar" aria-label="Basic example" style={{width: ((reviewCount["1"] * 100)/totalreview).toString() + "%"}} aria-valuenow={(reviewCount["1"] * 100)/totalreview } aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ms-3 mb-0 text-end">{reviewCount["1"]}</p>
                          </div>
                        </Col>
                      </div>
                      
                    </div>
                  </div>
                  ) : ("")}
                </Card>
            </Col> */}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Csv;
