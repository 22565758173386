import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, UncontrolledTooltip } from 'reactstrap';
import { product_description } from "../../Api";
import LeftSidebar from "../../Components/LeftSidebar";
import Topbar from "../../Components/Topbar";
import "./productdescription.scss";
// import {useSidebar} from '../../context/sidebarContext.jsx';

function ProductDescription() {
  const [productName, setProductName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bullets, setbullets] = useState("");
  const [gotResponse, setGotResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const {myValue, setMyValue} = useSidebar(false);

  let navigate = useNavigate();

  useEffect(() => {
    // setMyValue(false);

    // console.log("token",window.sessionStorage.getItem("token"))
    if (window.sessionStorage.getItem("token") == null) {
      navigate("/");
    }
  });


  const getProductDescription = async () => {
    setLoading(true)
    setGotResponse(false)
    const token = window.sessionStorage.getItem("token", "");
    const resp = await product_description(token, productName);
    console.log("Response", resp);

    if (resp.status === 200) {
      console.log("login", resp);
      setGotResponse(true)
      setTitle(resp.data.title)
      setDescription(resp.data.description)
      const bullet_points = resp.data.bullets
      console.log("bullets", bullet_points);
      setbullets(bullet_points)
    } else {
      console.log("in else", resp);
      setError(true);
    }

    setLoading(false)

  };
  const [isActive, setActive] = useState(false);

  const InstructionShow = () => {
    // console.log('click');
    setActive(!isActive);
  };


  return (
    <div className="product-description">
      <Topbar></Topbar>
      <div className="page-inner d-flex w-100">
        <LeftSidebar></LeftSidebar>
        <div className="content-box">
          <div className="content-box-inner">
            <div className="d-flex flex-column position-relative relative-section">
              <div className="bg-white header-section">
                <Container className="p-4 d-flex align-items-center gap-2">
                  <h6 className="fw-600 mb-0">Get Product Description</h6>
                  <InfoIcon id="UncontrolledTooltipExample" className='text-secondary'></InfoIcon>
                  <UncontrolledTooltip
                    placement="right"
                    target="UncontrolledTooltipExample"
                  >
                    <p>Provide your marketplace product name and we'll provide title, description and bullet points.</p>
                  </UncontrolledTooltip>
                </Container>
              </div>
              <div className="py-3 response-section">
                <Container className="h-100 p-4">
                  <Row className="h-100">
                    <div className="response-inner h-100">
                      {/* {loading ? (
                    <div className="loading bg-transparent h-100">
                      <div id="loading-spinner">
                      </div>
                    </div>
                  ) : ("")} */}
                      {gotResponse && !error ? (
                        <div className="p-1">
                          <div className="response-data">
                            <h4 className="fw-600">Product Details</h4>
                            <div class="my-3 title-div">
                              <h5 className="text-brand fw-600 mb-1">Title: </h5>
                              <p className="my-2 fw-500">{title}</p>
                            </div>
                            <div class="my-3 description-div">
                              <h5 className="text-brand fw-600 mb-1">Description: </h5>
                              <p className="my-2 fw-500">{description}</p>
                            </div>
                            <div class="my-3 pointers-div">
                              <h5 className="text-brand fw-600 mb-1">Feature Points: </h5>
                              <ul className="my-3 ps-4 mb-0">
                                {bullets.map((item, index) => (
                                  <li className="my-1" key={{ index }}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : ("")}
                    </div>
                  </Row>
                </Container>
              </div>
              {loading ? (
                <div className="loading bg-transparent h-100" style={{ minHeight: "100%" }}>
                  <div id="loading-spinner">
                  </div>
                </div>
              ) : ("")}
              <div className="position-fixed end-0 instructions-block overflow-hidden">
                <div className={"d-flex position-relative instructions-block-inner " + (isActive ? "active" : "")}>
                  <div className="align-self-center">
                    <button className={"btn fw-600 d-inline-block " + (isActive ? "btn-brand" : "btn-outline-brand")} style={{ transform: "rotate(90deg)", transformOrigin: "center center" }} onClick={InstructionShow}>Instructions</button>
                    {/* <button className=" bottom-0 btn btn-info flex-shrink-1 d-inline-block" onClick={InstructionShow}>Instructions</button> */}
                  </div>
                  <div className="bg-white d-inline-block rounded-3 instruction-details ps-3">
                    <ol className="p-sm-4 p-3 mb-0 ">
                      <li>Paste the name of the product in the input</li>
                      <li>We will generate "Title", "Description" and "Featur Points" for the product which will be SEO optimised.</li>
                      <li>Wait for analysis.</li>
                      <li>The results may not be accurate all the time.</li>
                      <li>Do not try to submit another product while one is being processed.</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="mt-auto py-2 upload-section position-sticky border-top bottom-0 w-100 bg-brand-light">
                <Container className="p-md-4 p-3">
                  <div className="d-flex flex-column mt-auto p-1 mb-2 mx-auto upload-inner">
                    <p className="error text-danger small mb-2"> {error ? "Some error occured, please try again !" : ""} </p>
                    <div className="d-flex align-items-center">
                      <input className="form-control me-md-4 me-3" autoFocus type="text" onChange={(e) => { setProductName(e.target.value); }} placeholder="Product name"></input>
                      <button onClick={getProductDescription} className="btn btn-brand">Submit</button>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            {/* <Col lg="6" className="p-md-3 p-2">
              <Card className="p-md-4 p-3 upload-card rounded-4 h-100">
                <div className="p-1">
                  <div className="upload">
                    <h4 className="text-start fw-600">Provide the Product Name to Get the Product Description.</h4>
                    <div className="file-input">
                      <p className="error">
                        {error ? "Some error occured, please try again !" : ""}
                      </p>
                      <div className="d-flex align-items-center">
                        <input className="form-control me-md-4 me-3" autoFocus type="text" onChange={(e) => { setProductName(e.target.value); }} placeholder="Product name"></input>
                        <button onClick={getProductDescription} className="btn btn-brand w-25">Submit</button>
                      </div>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <div className="mt-3">
                      <h4 className="fw-500">Instructions</h4>
                      <ol className="ps-4">
                        <li className="my-1">Paste the name of the product in the input</li>
                        <li className="my-1">we will generate "title", "description" and "Bullets" for the product which will be SEO optimised.</li>
                        <li className="my-1">The results may not be accurate all the time.</li>
                        <li className="my-1">Then we will process all the reviews.</li>
                        <li className="my-1">Do not try to input another product while one is being processed.</li>
                      </ol>
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg="6" className="p-md-3 p-2">
              <Card className="response-card rounded-4 p-md-4 p-3 h-100">
                {loading ? (
                  <div className="loading bg-transparent my-5 py-5">
                    <div id="loading-spinner">
                    </div>
                  </div>
                ) : ("")}
                
                {gotResponse && !error ?  (
                  <div className="p-1">
                    <div className="response-data">
                      <h4 className="fw-600">Product Details</h4>
                        <div class="my-3 title-div">
                          <h5 className="text-brand fw-600 mb-1">Title: </h5>
                          <p className="my-2 fw-500">{title}</p>
                        </div>
                        <div class="my-3 description-div">
                          <h5 className="text-brand fw-600 mb-1">Description: </h5>
                          <p className="my-2 fw-500">{description}</p>
                        </div>
                        <div class="my-3 pointers-div">
                          <h5 className="text-brand fw-600 mb-1">Feature Points: </h5>
                          <ul className="my-3 ps-2 mb-0">
                            {bullets.map((item, index) => (
                              <li className="my-1" key={{ index }}>{item}</li>
                              ))}
                          </ul>
                        </div>
                    </div>
                  </div>
                ) : ("")}
              </Card>
            </Col> */}
          </div>
        </div>
      </div>

      {/* <div className="instructions">
        <p className="heading">Instructions</p>
        <p>1. Paste the name of the product in the input</p>
        <p>2. we will generate "title", "description" and "Bullets" for the product which will be SEO optimised.</p>
        <p>3. The results may not be accurate all the time.</p>
        <p>4. Do not try to input another product while one is being processed.</p>
      </div>
      <div className="middle">
        <h3>write the product name below to start generating.</h3>
        <div className="upload">
          <div className="file-input">
            <p className="error">
              {error ? "Some error occured, please try again !" : ""}
            </p>
            
            <input
            className="asin-label"
            autoFocus
            type="text"
            onChange={(e) => {
              setProductName(e.target.value);
            }}
            placeholder="product name"
            ></input>
            <button onClick={getProductDescription} >Submit</button>
          </div>
        </div>

        {loading ? (
        <div className="loading">
          <p>Processing</p>
          <div id="loading-spinner">
          </div>
        </div>
      ) : (
        ""
        )}

        {gotResponse && !error ? (
          <div className="review-count">
            <p>
              Title :
            </p>
            <span>{title}</span>

          </div>
        ) : (
          ""
        )}
      </div>
      <div className="benefit">
        <h4>Details</h4>

        {gotResponse && !error ? (
          <div className="content">
             <p> Description:</p>
            <span className="top">
             {description}
            </span>
            
            <p> Bullets:</p>
            <ul>
            {bullets.map((item, index) => (
              <li key={{ index }}>{item}</li>
            ))}
          </ul>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
}

export default ProductDescription;
